import React from 'react'
import Helmet from 'react-helmet'

const Component: React.FC = () => {
  return (
    <main id="MainZone">
      <Helmet>
        <meta
          httpEquiv="refresh"
          content="0;URL='https://adventisthealth.org/pluskarla'"
        />
      </Helmet>
    </main>
  )
}

export default Component
